import { cva, cx } from 'class-variance-authority';
import { forwardRef } from 'react';
import { InternalLink } from './DefaultLinkSubTypes/InternalLink';
import { ExternalLink } from './DefaultLinkSubTypes/ExternalLink';
import { LinkProps, LinkSwitchType } from './types';
import ConditionalWrapper from '../ConditionalWrapper';
import NavLinkWrapper from './DefaultLinkSubTypes/NavLinkWrapper';

export const link = cva(['underline', 'hover:underline', 'cursor-pointer'], {
  variants: {
    color: {
      inherit: ['text-inherit'],
      default: [
        'text-primary-600',
        'active:text-primary-700',
        'focus:text-primary-700',
        'hover:text-primary-700',
      ],
      primaryHover: [
        'text-type-heading',
        'active:text-primary-600',
        'focus:text-primary-600',
        'hover:text-primary-600',
      ],
      white: [
        'text-white',
        'active:text-white',
        'focus:text-white',
        'hover:text-white',
      ],
      neutral: [
        'text-type-secondary',
        'active:text-type-secondary',
        'focus:text-type-secondary',
        'hover:text-type-secondary',
      ],
    },
    full: {
      true: [
        'after:z-[1]',
        'after:content-[" "]',
        'after:absolute',
        'after:inset-0',
      ],
      false: [],
    },
    underline: {
      true: [],
      false: ['no-underline'],
    },
  },
  defaultVariants: {
    color: 'default',
  },
});

export const DefaultLink: LinkProps = forwardRef(function Link(
  {
    navLinkWrapper,
    children,
    href,
    color,
    full,
    underline,
    className,
    ...props
  },
  ref
) {
  if (!href) return <></>;
  const switchLogic = (href: string) => {
    if (href[0] === '/') {
      return LinkSwitchType.internal;
    } else {
      return LinkSwitchType.external;
    }
  };
  const linkType = switchLogic(href);
  const renderLink = (string: LinkSwitchType) => {
    switch (string) {
      case LinkSwitchType.internal:
        return (
          <InternalLink
            href={href}
            ref={ref}
            color={color}
            className={cx(className)}
            {...props}
          >
            {children}
          </InternalLink>
        );
      case LinkSwitchType.external:
        return (
          <ExternalLink
            href={href}
            ref={ref}
            color={color}
            className={cx(className)}
            {...props}
          >
            {children}
          </ExternalLink>
        );
      default:
        break;
    }
  };

  const navLinkProps = {
    children,
    href,
    color,
    full,
    underline,
    className,
    ...props,
  };

  return (
    <ConditionalWrapper
      condition={!!navLinkWrapper}
      wrapper={(children) => (
        <NavLinkWrapper {...navLinkProps}>{children}</NavLinkWrapper>
      )}
    >
      {renderLink(linkType)}
    </ConditionalWrapper>
  );
});
