'use client';

import { useRouter } from 'next/router';
import { useState } from 'react';
import useLocaleCode from '~/hooks/useLocaleCode';
import { Close, Chat } from '@oresundsbron/icons';
export default function Chatbot() {
  const [showChatbot, setShowChatbot] = useState(false);
  const [chatbotLoaded, setChatbotLoaded] = useState(false);

  const toggleChatbot = () => {
    if (!chatbotLoaded) {
      // Load the chatbot for the first time when the button is clicked for performance reasons.
      // After that, just hide/show it with CSS on toggle to avoid destroy/reinitialization.
      setChatbotLoaded(true);
    }
    setShowChatbot(!showChatbot);
  };

  const { locale } = useRouter();
  const chatbotUrl = process.env.NEXT_PUBLIC_CHATBOT_URL;
  const localeCode = useLocaleCode(locale || '');

  return (
    <div className="fixed bottom-4 right-4 z-40">
      <button
        className="grid h-12 w-12 place-content-center rounded-full bg-primary-600 text-center hover:bg-primary-800"
        onClick={toggleChatbot}
        type="button"
      >
        <Chat fill="#fff" height={20} width={20} />
      </button>
      {chatbotLoaded && (
        <div
          className={`fixed bottom-0 right-0 h-[100dvh] w-full bg-white pb-12 sm:bottom-24 sm:right-4 sm:h-96 sm:w-auto sm:pb-0 sm:shadow-lg ${
            showChatbot ? 'block' : 'hidden'
          }`}
        >
          <button
            className="absolute right-2 top-1.5"
            type="button"
            onClick={toggleChatbot}
          >
            <Close fill="#fff" width={30} height={30} />
          </button>
          <iframe
            src={`${chatbotUrl}&locale=${localeCode}`}
            className="h-full w-full border-none"
          ></iframe>
        </div>
      )}
    </div>
  );
}
